<template>
    <Layout>
        <h4>Noc Revalidation Request</h4>
        <br />
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestNocRevalidation"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="noc.unit_id"
                            :options="mappedUserUnits"
                            @change="getNocs(noc.unit_id)"
                            :class="{
                                'is-invalid':
                                    submitted && $v.noc.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.noc.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.noc.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>

                <div
                    class="col-lg-6"
                    v-if="noc.unit_id && this.nocs.length == 0"
                >
                    <div class="form-group">
                        <label for="ownerfname">Noc for revalidation</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            disabled
                            placeholder="No units available"
                            id="username"
                        />
                    </div>
                </div>
                <div
                    class="col-lg-6"
                    v-if="noc.unit_id && this.nocs.length >= 1"
                >
                    <div class="form-group">
                        <label for="ownerfname">Noc for revalidation</label>

                        <b-form-select
                            id="ownerfname"
                            v-model="noc.noc_id"
                            name="ownerfname"
                            :options="mappedNocs"
                            :class="{
                                'is-invalid': submitted && $v.noc.noc_id.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.noc.noc_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.noc.noc_id.required"
                                >Please choose a value</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <submitButton />
        </form>
        <paymentModal
            ref="paymentWindow"
            page="NOC Revalidation"
            :requestId="requestId"
            :referenceNumber="refernceNumber"
            :modalTitle="modalTitle"
            :adminFees="adminFees"
            :urgentFees="urgentFees"
            :securityDepFees="securityDepFees"
            :adminVatFees="adminVatFees"
        />
    </Layout>
</template>
<script>
/* eslint-disable no-unused-vars */
import Layout from "../../layouts/main.vue";
import alertMixin from "../../../mixins/alertMixin";
import { BASE_URL, REQUEST_TYPES } from "../../common";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import paymentModal from "../payment/paymentModal.vue";
import submitButton from "../../../components/submitButton.vue";

export default {
    mixins: [alertMixin],
    components: {
        Layout,
        paymentModal,
        submitButton,
    },
    validations: {
        noc: {
            unit_id: { required },
            noc_id: { required },
        },
    },
    mounted() {
        this.getTermsConditions();
    },
    data() {
        return {
            requestId: "",
            refernceNumber: "",
            submitted: false,
            adminFees: "",
            adminVatFees: "",
            securityDepFees: "",
            urgentFees: "",
            serviceFees: "",
            modalTitle: "",
            payment: "",
            nocs: [],
            choosenNoc: "",
            noc: {
                unit_id: "",
                noc_id: "",
            },
        };
    },
    methods: {
        getNocs(id) {
            console.log(id);
            if (localStorage.cs_user_token) {
                axios
                    .get(
                        `${BASE_URL}get-nocs/${id}?token=${localStorage.cs_user_token}`
                    )
                    .then((res) => {
                        this.nocs = res.data.nocs;
                    });
            }
        },

        requestNocRevalidation() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                let result = this.nocs.find((x) => x.id === this.noc.noc_id);
                let payload = {
                    noc_id: this.noc.noc_id,
                    noc_ref_no: result.ref_no,
                    noc_type: result.requesttype,
                    unit_id: this.noc.unit_id,
                };
                console.log(payload);

                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}noc-revalidations?token=${localStorage.cs_user_token}`,
                            payload
                        )
                        .then((res) => {
                            console.log(res.data.response);
                            this.requestId = res.data.response.request_id;
                            this.refernceNumber = res.data.response.ref_no;
                            this.modalTitle =
                                "Payment for  " +
                                " " +
                                res.data.response.ref_no;
                            loader.hide();
                            this.$refs.paymentWindow.showModal();
                            this.noc.unit_id = "";
                            this.noc.noc_id = "";
                            this.submitted = false;
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                            this.noc.unit_id = "";
                            this.noc.noc_id = "";
                            this.submitted = false;
                        });
                }
            }
        },

        getTermsConditions() {
            if (localStorage.cs_user_token) {
                let payload = {
                    request_type: REQUEST_TYPES.find(
                        (x) => x.type === "NOC Revalidation"
                    ).id,
                };
                axios
                    .post(
                        `${BASE_URL}getrequeststermsconditions?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        this.adminFees = res.data.response.service_fees;
                        this.serviceFees = res.data.response.service_fees;
                        this.urgentFees = res.data.response.urgent_fees;
                        this.adminVatFees = res.data.response.admin_vat_fees;
                        this.securityDepFees =
                            res.data.response.security_deposit;
                    });
            }
        },
    },
    computed: {
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
        mappedNocs() {
            return this.nocs.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.ref_no,
                };
            });
        },
    },
};
</script>
